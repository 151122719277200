import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MyLoader from "./MyLoader";

export default function PaymentSuccessRedirect() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  //prevents refresh
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Modern browsers require setting returnValue to trigger the confirmation dialog
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const onHandlePress = () => {
    setLoading(true);
    function generateRandomString(length) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charactersLength);
        result += characters[randomIndex];
      }
      return result;
    }
    const randomString = generateRandomString(12);

    const apiEndpoint = "https://nodejsapifinal.vercel.app/balance/";
    const userId = localStorage.getItem("uid");
    const postData = async (payload) => {
      try {
        const response = await fetch(apiEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers you need here
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }

        const result = await response.json();
        setData(result); // Save the result to state or handle it as needed
      } catch (error) {
        setError(error); // Handle errors here
        alert("Something went wrong!");
      } finally {
        setTimeout(() => {
          setLoading(false);
          navigate("/afterPaymentSuccess");
        }, 1000);
      }
    };
    const payload = { userId, balance: 30, paymentSessionId: randomString };
    postData(payload);
  };

  useEffect(() => {
    onHandlePress();
  }, []);
  return (
    <div style={styles.container}>
      {loading && (
        <div>
          <MyLoader />
          <p style={{ color: "#FFF", marginTop: 100 }}>
            Processing your payment. Don't press refresh or back button
          </p>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#000000",
    color: "#333",
  },
};
