import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./styleFolder/RechargePlans.css";
import SuccessModal from "./components/PaymentSuccessModal"; // Import the modal component

const RechargePlans = () => {
  const plans = [
    { amount: 10, description: "Basic plan", amountInPaise: 1000 },
    { amount: 50, description: "Standard plan", amountInPaise: 5000 },
    { amount: 100, description: "Premium plan", amountInPaise: 10000 },
    { amount: 200, description: "Platinum plan", amountInPaise: 20000 },
  ];

  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newBalance, setNewBalance] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const navigate = useNavigate();

  const apiEndpoint = "https://nodejsapifinal.vercel.app/balance/";
  const userId = localStorage.getItem("uid");
  const email = localStorage.getItem("email");

  useEffect(() => {
    // Fetch initial balance here if needed
    const fetchBalance = async () => {
      try {
        const response = await fetch(apiEndpoint + userId);
        const result = await response.json();
        setBalance(result.balance);
      } catch (error) {
        console.error('Error fetching balance:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBalance();
  }, [apiEndpoint, userId]);

  const openRazorpay = (amountInPaise, amount) => {
    if (window.Razorpay) {
      const options = {
        key: "rzp_test_m8dmpOUKZ9faIt",
        amount: amountInPaise,
        currency: "INR",
        name: "MUJJU.IN",
        description: "Recharge wallet",
        handler: function (response) {
          if (response.razorpay_payment_id) {
            handlePaymentSuccess(amount, response.razorpay_payment_id);
          }
        },
        notes: {
          address: "Razorpay Corporate office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      const pay = new window.Razorpay(options);
      pay.open();
    } else {
      console.error("Razorpay SDK not loaded");
    }
  };

  const handlePaymentSuccess = async (amount, paymentId) => {
    setProcessingPayment(true); // Show loading state
    try {
      const newBalance = balance + amount;
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          balance:amount,
          // email,
          paymentSessionId:paymentId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update balance');
      }

      const result = await response.json();
      setNewBalance(result.balance);
      setModalVisible(true); // Show the success modal
    } catch (error) {
      console.error('Error updating balance:', error);
      alert('Transaction Failed. Please contact support@mujju.in');
    } finally {
      setProcessingPayment(false); // Hide loading state
    }
  };

  const closeModal = () => setModalVisible(false);

  return (
    <div className="recharge-plans-container">
      <h1>Recharge Plans</h1>
      <p className="charge-info">
        * You will be charged 50 paisa per calculation hit
      </p>
      {loading ? (
        <div className="loader">Loading...</div> // Display loader while fetching balance
      ) : (
        <div className="plans">
          {plans.map((plan, index) => (
            <div className="plan-card" key={index}>
              <h2>₹{plan.amount}</h2>
              <p>{plan.description}</p>
              <button
                onClick={() => openRazorpay(plan.amountInPaise, plan.amount)}
                className="btn-recharge"
                disabled={processingPayment} // Disable button during payment processing
              >
                {processingPayment ? "Processing..." : "Recharge Now"}
              </button>
            </div>
          ))}
        </div>
      )}
      <SuccessModal isVisible={modalVisible} onClose={closeModal} />
    </div>
  );
};

export default RechargePlans;
