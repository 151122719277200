import React, { useEffect, useRef, useState } from "react";

import MyCheckbox from "./components/MyCheckbox";

import ReactGA from "react-ga4";
import InfoModal from "./components/InfoModal";

import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { firebaseConfig2 } from "./firebaseConfig";

import "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { settings } from "firebase/analytics";
import MyLoader from "./MyLoader";

// const apiEndpoint = "http://localhost:3000/balance"; //localhost
const apiEndpoint = "https://nodejsapifinal.vercel.app/balance"; //live

const userId = localStorage.getItem("uid");

const firebaseConfig = {
  apiKey: "AIzaSyCme_UWPbcSxU-PHxUY3qxZS8BbIWuwYaU",
  authDomain: "timesheet-9363d.firebaseapp.com",
  projectId: "timesheet-9363d",
  storageBucket: "timesheet-9363d.appspot.com",
  messagingSenderId: "829189960636",
  appId: "1:829189960636:web:16f3f9436176889f6bf1b9",
  measurementId: "G-107SX68EEE",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig, firebaseConfig2);
const firestore = getFirestore(app);

ReactGA.initialize("G-6LQ0CFS7ED"); // original timesheet

//for logout
const auth = getAuth();

const isMobile = window.innerWidth <= 768; // Change the value as needed

const CalculatorPage = () => {
  const [inputData, setInputData] = useState("");
  const [displayDuration, setDisplayDuration] = useState("");
  const [position, setPosition] = useState({ top: 10, left: 20 });
  const [timeToLeave, setTimeToLeave] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [breakDuration, setBreakDuration] = useState("");
  const [duplicateExists, setDuplicateExists] = useState(false);

  const [uid, setUid] = useState("");
  const [walletBalance, setWalletBalance] = useState("");
  const [error, setError] = useState("");
  const [newBalanceD, setNewBalanceD] = useState("");
  const [loading, setLoading] = useState(false);
  const amountToSubtract = -1;
  const [userInfoName, setUserInfoName] = useState("");
  const [inTime, setInTime] = useState("");
  useEffect(() => {
    setLoading(true);
    async function getBalanceNow() {
      const useridNow = localStorage.getItem("uid");
      try {
        // Perform the fetch request
        const response = await fetch(`${apiEndpoint}/${useridNow}`, {
          // mode:'no-cors'
        });

        // Check if the response is ok (status in the range 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the response JSON
        const data = await response.json();

        // Use the fetched data (for example, updating the state or displaying it)
        // console.log("Fetched data:", data);
        // Handle the data as needed
        setWalletBalance(data.balance);
      } catch (error) {
        // Catch and log any errors that occurred during fetch
        console.error("Error fetching balance:", error.message);
        // Handle or display the error message as needed
        alert(error.message);
      } finally {
        setLoading(false);
      }
    }
    setTimeout(() => {
      getBalanceNow(); // Call the async function
    }, 1500);
  }, [apiEndpoint, userId, amountToSubtract]); // Ad

  const handleSubtractBalance = async () => {
    // Ensure newBalanceD is updated before making the API call
    const userId = localStorage.getItem("uid");

    try {
      if (!userId || isNaN(parseFloat(amountToSubtract))) {
        throw new Error("Invalid input");
      }

      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ balance: amountToSubtract, userId }),
      });

      if (response.ok) {
        const updatedBalance = await response.json();
        // console.log("Updated balance:", updatedBalance);
        setWalletBalance(updatedBalance.balance);
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (err) {
      console.error("Error in handleSubtractBalance:", err.message);
      setError(`Failed to subtract balance: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const displayAd = () => {
    const script = document.createElement("script");
    script.src = "https://alwingulla.com/88/tag.min.js";
    script.setAttribute("data-zone", "31572");
    script.setAttribute("async", "");
    script.setAttribute("data-cfasync", "false");

    // Append the script to the document body
    document.body.appendChild(script);

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  };

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsModalVisible(true);

      //   alert("This is beta version.\n 1.Enter the date format in 24hrs. \n2.For any queries or assistance, please feel free to contact us via email at support@mujju.in.")
    }, 2000);
  }, []);

  const formatTime = (totalSeconds) => {
    const totalMinutes = Math.floor(totalSeconds / 60);
    const hours24 = Math.floor(totalMinutes / 60) % 24;
    const hours12 = hours24 % 12 || 12;
    const minutes = totalMinutes % 60;
    const ampm = hours24 < 12 ? "AM" : "PM";

    return `${hours12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
  };

  const handleTextAreaChange = (e) => {
    setInputData(e.target.value);
  };

  var multilineString = `${inputData}`;
  var linesArray = multilineString.split("\n");
  // console.log('linesarray',linesArray)

  // Function to compare and remove entries, keeping the latest timestamp among duplicates with the same status
  const logEntries = linesArray;
  function removeDuplicateEntries(entries) {
    const result = [];
    let prevStatus = null;
    let prevTimestamp = null;

    for (let i = 0; i < entries.length; i++) {
      const currentStatus = entries[i].includes("Entry Granted")
        ? "Entry Granted"
        : "Exit Granted";
      const currentTimestamp = new Date(entries[i].split("\t")[3]).getTime();

      if (i === 0 || currentStatus !== prevStatus) {
        result.push(entries[i]);
        prevStatus = currentStatus;
        prevTimestamp = currentTimestamp;
      } else if (currentTimestamp > prevTimestamp) {
        result.pop(); // Remove the previous entry
        result.push(entries[i]); // Keep the current entry with the latest timestamp
        prevTimestamp = currentTimestamp;
      }
    }

    return result;
  }

  // Removing duplicate entries with the same status, keeping the entry with the latest timestamp
  const filteredEntries = removeDuplicateEntries(logEntries);

  // Displaying the filtered entries
  filteredEntries.forEach((entry) => {
    // console.log('logentries',entry);
  });

  //switch between duplicate and standard

  const checkDpulicateEntry = () => {
    if (duplicateExists) {
      return filteredEntries;
    } else {
      return linesArray;
    }
  };

  // var strings = linesArray;      //before duplicate entry logic
  // var strings = filteredEntries    //after writing logic to remove duplicate entry and keep the latest.
  var strings = checkDpulicateEntry();
  var pattern = /(\d{2}:\d{2}:\d{2})/g;
  var extractedTimes = [];

  for (var i = 0; i < strings.length; i++) {
    var matches = strings[i].match(pattern);
    if (matches) {
      extractedTimes = extractedTimes.concat(matches);
    }
  }

  var times = extractedTimes;
  var timePairs = [];

  for (var i = 0; i < times.length; i += 2) {
    var fromTime = times[i];
    var toTime = times[i + 1] || getCurrentTime();

    var fromDate = new Date(`1970-01-01T${fromTime}`);
    var toDate = new Date(`1970-01-01T${toTime}`);

    var durationMilliseconds = toDate - fromDate;
    var durationHours = Math.floor(durationMilliseconds / 3600000);
    var durationMinutes = Math.floor((durationMilliseconds % 3600000) / 60000);
    var durationSeconds = Math.floor((durationMilliseconds % 60000) / 1000);

    var duration = `${durationHours}:${durationMinutes}:${durationSeconds}`;
    timePairs.push({ from: fromTime, to: toTime, duration: duration });
  }

  // Calculate Break Hours Taken
  const calculateBreakHours = () => {
    let breakDurationInSeconds = 0;

    for (let i = 1; i < timePairs.length; i++) {
      const breakStart = new Date(`1970-01-01T${timePairs[i - 1].to}`);
      const breakEnd = new Date(`1970-01-01T${timePairs[i].from}`);

      const breakDuration = breakEnd - breakStart;
      breakDurationInSeconds += breakDuration / 1000; // Convert milliseconds to seconds
    }

    // Convert break duration to hours, minutes, and seconds
    const breakHours = Math.floor(breakDurationInSeconds / 3600);
    const breakMinutes = Math.floor((breakDurationInSeconds % 3600) / 60);
    const breakSeconds = Math.floor(breakDurationInSeconds % 60);

    return `${breakHours.toString().padStart(2, "0")}:${breakMinutes
      .toString()
      .padStart(2, "0")}:${breakSeconds.toString().padStart(2, "0")}`;
  };

  const openNewTab = () => {
    // Specify the URL you want to open in a new tab
    const url = "https://amzn.to/3tU1pAk";

    // Open the URL in a new tab
    window.open(url, "_blank");
  };

  //signout

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        setWalletBalance("");

        navigate("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const onRechargeBtnPress = () => {
    navigate("/rechargePlans");
  };
  const onPaymentButtonPress = () => {
    navigate("/paymentCheckout");
  };

  const onSubmitButtonPress = async () => {
    // displayAd()
    // console.log(timePairs)
    let totalDurationInSeconds = 0;

    timePairs.forEach((pair) => {
      const [hours, minutes, seconds] = pair.duration.split(":").map(Number);
      const durationInSeconds = hours * 3600 + minutes * 60 + seconds;
      totalDurationInSeconds += durationInSeconds;
    });

    if (totalDurationInSeconds < 28800) {
      const remainingSeconds = 28800 - totalDurationInSeconds;

      // Get current IST time
      const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5.5
      const currentISTTime = new Date(Date.now() + istOffset);

      const totalToLeaveSeconds =
        currentISTTime.getTime() / 1000 + remainingSeconds;

      // Format the result into 12-hour time string
      const formattedTimeToLeave = formatTime(totalToLeaveSeconds);

      // Log or use the result as needed
      // console.log("Time to Leave:", formattedTimeToLeave);
      setTimeToLeave(formattedTimeToLeave);

      // Set the variable or display it as needed
      // setYourVariableHere(formattedTimeToLeave);
    } else if (totalDurationInSeconds > 28800) {
      setTimeToLeave(" 8hrs completed");
    } else if (totalDurationInSeconds < 0) {
      alert("Something Went Wrong!!");
    }

    const totalHours = Math.floor(totalDurationInSeconds / 3600);
    const totalMinutes = Math.floor((totalDurationInSeconds % 3600) / 60);
    const totalSeconds = totalDurationInSeconds % 60;
    const formattedTotalDuration = `${totalHours
      .toString()
      .padStart(2, "0")}:${totalMinutes
      .toString()
      .padStart(2, "0")}:${totalSeconds.toString().padStart(2, "0")}`;

    setDisplayDuration(formattedTotalDuration);
    if (totalHours < 0 || totalMinutes < 0 || totalSeconds < 0) {
      setDisplayDuration("");
      // console.log('error minus val',formattedTotalDuration)
      alert("Something went wrong... ");
    }
    ReactGA.event({
      category: "your category",
      action: "button pressed",
      label: "your label", // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });

    //handle empty box
    if (inputData == "") {
      setDisplayDuration("");
      setTimeToLeave("");
      alert("Enter something...");
    }
    const breakHoursTaken = calculateBreakHours();
    // console.log('Break Hours Taken:', breakHoursTaken);
    setBreakDuration(breakHoursTaken);

    try {
      // Add the value to Firestore
      const docRef = await addDoc(collection(firestore, "myUser"), {
        value: linesArray,
        // You can add more fields here if needed
      });

      // console.log('Document written with ID: ', docRef.id);
      // Optionally, you can reset the input value after submitting
      // setInputValue('');
    } catch (error) {
      console.error("Error adding value to Firestore: ", error);
    }
  };

  const postUserData = async () => {
    try {
      // Data to be sent in the POST request
      const dataToPost = {
        name: userInfoName,
        time: inTime,
      };

      // Replace 'https://api.example.com/data' with your API endpoint
      const response = await fetch("https://nodejsapifinal.vercel.app/variable-details", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToPost),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  //collect user data

  const extractNameAndFirstTime = (logEntries) => {
    const namePattern = /(\d+) (\w+ \w+)/; // Pattern to capture "ID Name"
    const timePattern = /\d{2}:\d{2}:\d{2}/; // Pattern to capture time (HH:MM:SS)
    const nameTimes = {};

    logEntries.forEach((entry) => {
      const nameMatch = entry.match(namePattern);
      const timeMatch = entry.match(timePattern);

      if (nameMatch) {
        const name = nameMatch[2];
        if (!nameTimes[name]) {
          nameTimes[name] = timeMatch ? timeMatch[0] : null;
        }
      }
    });

    // Extract the first unique name and its first occurrence time
    const names = Object.keys(nameTimes);
    if (names.length > 0) {
      return {
        name: names[0],
        time: nameTimes[names[0]],
      };
    }

    return { name: null, time: null };
  };

  //check wallet bal and submit
  const checkBalanceAndSubmit = async () => {

    if (walletBalance > 0) {
      setLoading(true);

      const { name, time } = extractNameAndFirstTime(linesArray);
      setUserInfoName(name);
      setInTime(time);
      

      try {
        // Call handleSubtractBalance and wait for it to complete
        await handleSubtractBalance();
        // Call onSubmitButtonPress after balance deduction
        onSubmitButtonPress();
        postUserData()
      } catch (error) {
        // Handle any errors that might occur during balance deduction
        console.error("Error during balance deduction:", error.message);
      }
    } else {
      alert("Please Recharge your account");
    }
  };
  const onAcceptBtnPress = () => {
    setIsModalVisible(false);
    navigate("/paymentCheckout");
    // openNewTab()
  };

  return (
    <div style={containerStyle}>
      {/* <div style={textViewStyle}>{displayDuration}</div> */}
      {loading && <MyLoader />}
      <InfoModal
        showModal={isModalVisible} //default isModalVisible
        onAcceptButtonClick={onAcceptBtnPress}
        closeModal={() => setIsModalVisible(false)}
      />

      <div style={containerStyle1}>
        <div>
          <div style={timeTextStyle}>Present Time</div>
          <div style={timeStyle}>{displayDuration}</div>
        </div>
        <div>
          <div style={timeTextStyle}>Approx. Time to Leave</div>
          <div style={timeStyle}>{timeToLeave}</div>
        </div>
        <div>
          <div style={timeTextStyle}>Break Hours</div>
          <div style={timeStyle}>{breakDuration}</div>
        </div>
        <div>
          <div style={timeTextStyle}>Balance</div>
          <div style={timeStyle}>₹ {walletBalance}</div>
        </div>
      </div>

      <textarea
        style={textareaStyle}
        placeholder="Enter something..."
        onChange={handleTextAreaChange}
        value={inputData}
      ></textarea>
      <MyCheckbox
        isChecked={duplicateExists}
        handleCheckboxChange={() => setDuplicateExists(!duplicateExists)}
      />
      <div style={btnContainerStyle}>
        {/* pg button  */}
        {/* <button onClick={onRechargeBtnPress} style={buttonStyle}>
          Recharge
        </button> */}
        <button onClick={onPaymentButtonPress} style={buttonStyle}>
          Recharge
        </button>
        <button onClick={checkBalanceAndSubmit} style={buttonStyle}>
          Submit
        </button>
        <button onClick={handleSignOut} style={buttonStyle}>
          Logout
        </button>
      </div>

      <div>
        <a href="privacy" style={linkStyle}>
          Privacy Policy |{" "}
        </a>
        <a href="/terms" style={linkStyle}>
          Terms and Conditions |{" "}
        </a>
        <a href="/contactUs" style={linkStyle}>
          Contact Us
        </a>
      </div>
    </div>
  );
};

const btnContainerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center", // Center the buttons horizontally
  alignItems: "center", // Center the buttons vertically
};

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  backgroundColor: "black",
};

const linkStyle = {
  color: "white",
  textDecoration: "none", // Optional: Remove underline if needed
};

const textViewStyle = {
  fontSize: "30px",
  color: "white",
  marginTop: "20px",
  marginBottom: "10px",
};

const textareaStyle = {
  height: "80vh",
  width: "60%",
  fontSize: "16px",
  padding: "10px",
  border: "2px solid white",
  backgroundColor: "transparent",
  color: "white",
  outline: "none",
  verticalAlign: "top",
};

const buttonStyle = {
  backgroundColor: "red",
  color: "white",
  border: "none",
  borderRadius: "25px",
  padding: "10px 25px",
  fontSize: "18px",
  cursor: "pointer",
  marginBottom: "20px",
  marginTop: "20px",
  margin: "0 10px", // Add some space between buttons
};
const containerStyle1 = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  // height: '100vh',
  // backgroundColor: 'lightgray',
  width: isMobile ? "80%" : "40%",
  marginTop: "20px",
};

const timeTextStyle = {
  // fontSize: '16px',
  fontSize: isMobile ? "12px" : "16px", // Adjust font size for mobile and desktop

  fontWeight: "bold",
  marginBottom: "5px",
  color: "#FFF",
};

const timeStyle = {
  // fontSize: '24px',
  fontSize: isMobile ? "16px" : "23px", // Adjust font size for mobile and desktop

  marginBottom: "20px",
  color: "#FFF",
  textAlign: "center", // Center the text
};

function getCurrentTime() {
  var now = new Date();
  var hours = now.getHours().toString().padStart(2, "0");
  var minutes = now.getMinutes().toString().padStart(2, "0");
  var seconds = now.getSeconds().toString().padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
}

export default CalculatorPage;
