// src/components/SuccessModal.js
import React from "react";
import "../styleFolder/PaymentSuccessModal.css"; // Create a CSS file for styling if needed
import { useNavigate } from "react-router-dom";

const SuccessModal = ({ isVisible, onClose }) => {
  const navigate = useNavigate();

  if (!isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Payment Successful</h2>
        <p>Your balance has been updated successfully.</p>
        <button onClick={() => navigate('/calc')}>Go to Home</button>
        <button onClick={onClose} className="btn-close">Close</button>
      </div>
    </div>
  );
};

export default SuccessModal;
