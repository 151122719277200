import React, { useState } from 'react';
import MyLoader from './MyLoader'
import { useNavigate } from 'react-router-dom';
export default function ReportTheIssue() {
  const [formData, setFormData] = useState({
    paidAmount: '',
    paymentId: '',
    receivedEmailId: '',
    registeredEmailId: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // New state for submission status
  const [loading,setLoading]=useState(false)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async(e) => {
    setLoading(true)
    e.preventDefault();
    // Here you would handle the form submission logic
    // For now, we'll just update the state to hide the form and show a confirmation message
    
    const {paymentId,paidAmount,registeredEmailId}=formData
    try {
        const response = await fetch('https://nodejsapifinal.vercel.app/report-balance', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({"paymentId":paymentId,"amount":Number(paidAmount),"email":registeredEmailId}),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        if(response.ok){
          setIsSubmitted(true);
        }
  
        setIsSubmitted(true);
      } catch (error) {
        // setError('There was a problem submitting the form.');
      } finally {
        setLoading(false)
      }
  };
const navigate=useNavigate()
  return (
    <div style={styles.container}>
      {loading && <MyLoader/>}
      {!isSubmitted ? (
        <>
          <h1 style={styles.title}>Report Payment Issue</h1>
          <form onSubmit={handleSubmit} style={styles.form}>
            <label style={{ ...styles.label, color: '#FFF' }}> {/* Sea green */}
              Paid Amount:
              <input
                type="text"
                name="paidAmount"
                value={formData.paidAmount}
                onChange={handleChange}
                style={styles.input}
              />
            </label>
            <label style={{ ...styles.label, color: '#FFF' }}> {/* Steel blue */}
              Payment ID Received on Email:
              <input
                type="text"
                name="paymentId"
                value={formData.paymentId}
                onChange={handleChange}
                style={styles.input}
              />
            </label>
            
            <label style={{ ...styles.label, color: '#FFF' }}> {/* Orange red */}
              Registered Email ID:
              <input
                type="email"
                name="registeredEmailId"
                value={formData.registeredEmailId}
                onChange={handleChange}
                style={styles.input}
              />
            </label>
            <button type="submit" style={styles.button}>Submit</button>
          </form>
        </>
      ) : (
        <div style={styles.confirmation}>
          Thank you for submitting. Our team will resolve the issue shortly.
          <button onClick={()=>navigate('/calc')} style={styles.button}>Return to Dashboard</button>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: '#000',
    color: '#fff',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '600px',
    margin: '0 auto',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#fff',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: '10px',
  },
  input: {
    backgroundColor: '#333',
    border: '1px solid #444',
    color: '#fff',
    padding: '10px',
    borderRadius: '4px',
    width: '100%',
    marginTop: '5px',
    boxSizing: 'border-box',
  },
  button: {
    backgroundColor: '#007bff',
    border: 'none',
    color: '#fff',
    padding: '10px',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '20px',
    fontSize: '16px',
  },
  confirmation: {
    textAlign: 'center',
    fontSize: '18px',
    color: '#fff',
  },
  button: {
    marginTop: '10px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
  }
};
