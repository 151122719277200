// src/Dashboard.js
import React, { useEffect, useState } from "react";
import "./styleFolder/AdminDashboard.css";
import DashboardModal from "./DashboardModal";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import MyLoader from "./MyLoader";




const Dashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState([]);
  const [loading,setLoading]=useState(false)
  useEffect(() => {
    setLoading(true)
    const adminStatus = localStorage.getItem("admin");
    setIsLoggedIn(adminStatus === "true");
  }, []);

  //get all users
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace 'https://api.example.com/data' with your API endpoint
        const response = await fetch("https://nodejsapifinal.vercel.app/users");

        // Check if the response is ok (status code in the range 200-299)
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        // Parse the JSON data
        const result = await response.json();
        setUsers(result);
        // Update state with the fetched data
        console.log(result);
      } catch (error) {
        // Handle errors here
        // setError(error.message);
      } finally {
        // Set loading to false whether fetch succeeds or fails
        // setLoading(false);
      }
    };

    const fetchTransactions = async () => {
      try {
        // Replace 'https://api.example.com/data' with your API endpoint
        const response = await fetch(
          "https://nodejsapifinal.vercel.app/positive-transactions"
        );

        // Check if the response is ok (status code in the range 200-299)
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        // Parse the JSON data
        const result = await response.json();
        setTransactionData(result);
        // Update state with the fetched data
        // console.log("td-->", result);
      } catch (error) {
        // Handle errors here
        // setError(error.message);
      } finally {
        // Set loading to false whether fetch succeeds or fails
        setLoading(false);
      }
    };

    fetchData();
    fetchTransactions();
  }, []); // Empty depend

  const handleLogout = () => {
    localStorage.setItem("admin", "false");
    setIsLoggedIn(false);
    navigate("/admin");
  };

  const handleRechargeWallets = () => {
    setIsModalOpen(true);
  };

  const handleToggleView = () => {
    setShowUsers(!showUsers);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (!isLoggedIn) {
    // Redirect or show an alternative component when not logged in
    return <div>Please log in to access the dashboard.</div>;
  }

  const handleUserDetails = ()=>{
    navigate('/userDetails')
  }
  return (
    <div className="dashboard">
      {loading && <MyLoader/>}
      <h1>Admin Dashboard</h1>
      <div className="buttons">
        <button onClick={handleLogout} className="btn logout-btn">
          Logout
        </button>
        <button onClick={handleRechargeWallets} className="btn recharge-btn">
          Recharge Wallets
        </button>
        <button onClick={handleToggleView} className="btn toggle-btn">
          {showUsers ? "View Transactions" : "Get All Users"}
        </button>
        <button onClick={handleUserDetails} className="btn logout-btn">
          User Details
        </button>
      </div>
      {!showUsers ? (
        <table>
          <thead>
            <tr>
              <th>Sno</th>
              <th>Payment Session ID</th>
              <th>Email</th>

              <th>Transaction Amount</th>
              <th>Transaction Date</th>
              <th>Transaction Time</th>
            </tr>
          </thead>
          <tbody>
            {transactionData.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.paymentSessionId}</td>
                <td>{item.email}</td>

                <td>{item.amount}</td>
                <td>{moment(item?.date).format("MMMM D, YYYY")}</td>
                <td>{moment(item?.date).format(" hh:mm A")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Sno</th>
              <th>Email</th>
              <th>User ID</th>
              <th>Wallet Balance</th>
              <th>Last Recharge Date</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user,index) => (
              <tr key={index}>
                <td>{index+1}</td>
                <td>{user.email}</td>
                <td>{user.userId}</td>
                <td>{user.balance}</td>
                <td>
                  {user.lastRechargeDate
                    ? moment(user.lastRechargeDate).format("MMMM D, YYYY")
                    : "-"}
                </td>
                <td>
                  {user.lastRechargeDate
                    ? moment(user.lastRechargeDate).format("hh:mm")
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <DashboardModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default Dashboard;
