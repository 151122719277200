// src/LoginPage.js
import React, { useState } from "react";
import "./styleFolder/AdminLogin.css"; // Optional: for custom styling
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const adminEmail = process.env.REACT_APP_ADMIN_EMAIL
  const adminPass = process.env.REACT_APP_ADMIN_PASS


  const handleSubmit = (e) => {
    e.preventDefault();
    // Simple validation
    if (!email || !password) {
      setError("Please fill in both fields.");
      return;
    }

   
  };

  const handleLogin = () => {
    if (email === adminEmail && password === adminPass) {
      navigate("/adminDashboard");
      localStorage.setItem("admin", true);
    } else {
      alert("Invalid Credentials!");
    }
  };

  return (
    <div className="login-container">
      <h2>Admin Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p className="error">{error}</p>}
        <button onClick={handleLogin} type="submit">
          Login
        </button>
      </form>
    </div>
  );
};

export default AdminLogin;
