// Modal.js
import React from "react";
import "../styleFolder/Modal.css"; // You can create your own CSS file for styling the modal
import InfoIcon from "@mui/icons-material/Info"; // Import Info icon from Material-UI

const InfoModal = ({ showModal, closeModal, onAcceptButtonClick }) => {
  return (
    <div className={`modal ${showModal ? "show" : ""}`}>
      <div className="modal-content">
        <span className="close" onClick={closeModal}>
          <i
            className="close-icon"
            style={{ fontSize: "35px", cursor: "pointer" }}
          >
            ×
          </i>
        </span>
        <div className="report-issue">
          <p>
            If your balance hasn’t updated, please{" "}
            <a href="/report">click here to report the issue</a>
          </p>
        </div>
        <h2>{/* You can add a title here if needed */}</h2>
        <div className="instructions">
          <ul className="bullet-list">
            <p>
              <strong>Basic plan:</strong> Get 30 hits for just ₹12!
            </p>
          </ul>
        </div>
        <p>
          Please read our <a href="/terms">terms and conditions</a> before use.
        </p>
        <button className="accept-button" onClick={onAcceptButtonClick}>
          Recharge Now!
        </button>
      </div>
    </div>
  );
};

export default InfoModal;
