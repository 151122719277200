import React, { useState } from "react";
import "./styleFolder/LoginForm.css"; // Import the CSS file for styling
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { firebaseConfig } from "./firebaseConfig";
import { app } from "./CalculatorPage";
import { useNavigate } from "react-router-dom";
import Footer from "./components/Footer";

function Login() {
  const [userData, setUserData] = useState([]);

  
  const checkExistingUsers = async (userid,email) => {
    const url = "https://nodejsapifinal.vercel.app/check-or-create-user"; // Replace with your API endpoint

    const payload = {
      userId: userid,
      balance: 3,
      email: email,
    };

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const data = await res.json();
      console.log("verify user", data);
    } catch (err) {
      console.log(err.message);
    }
  };
  








  const navigate = useNavigate();
  // Initialize Firebase Authentication and get a reference to the service
  const auth = getAuth(app);

  const handleOnSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Extract the values of username and password fields
    const username = event.target.elements.username.value;
    const password = event.target.elements.password.value;

    // Log the values to the console

    signInWithEmailAndPassword(auth, username, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
        // console.log("User", user);
        if (user) {
          navigate("/calc");
          // setUserData(user); // Save user info
          console.log(userData)
          localStorage.setItem("uid", user?.uid); // Save tok
          localStorage.setItem('userEmail',user?.email)
          checkExistingUsers(user?.uid,user?.email)
            
        }
      })
      .catch((error) => {
        const errorMessage = error.message;
        alert(errorMessage);
      });

    // You can add your logic for authentication or API calls here
    // For now, let's just log the values and reset the form
    event.target.reset(); // Reset the form fields after logging
  };
  return (
    <>
      <div className="login-heading">
  <h2>Login to use timesheet calculator</h2>
</div>
<div className="login-form-container">
  <form className="login-form" onSubmit={handleOnSubmit}>
    <label htmlFor="username">Email:</label>
    <input type="text" id="username" name="username" required />

    <label htmlFor="password">Password:</label>
    <input type="password" id="password" name="password" required />

    <button type="submit" className="login-button">
      Login
    </button>
    <div className="register-link">
      Don't have an account? <a href="/register">Register</a>
    </div>
  </form>
  <div className="additional-links">
    <a href="/about">About Us</a>
    <a href="/terms">Terms and Conditions</a>
    <a href="/privacy">Privacy Policy</a>
    <a href="/contactUs ">Contact Us</a>
  </div>
  <Footer/>
</div>

    </>
  );
}

export default Login;
