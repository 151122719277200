import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import CalculatorPage from "./CalculatorPage";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ContactUs from "./components/ContactUs";
import Login from "./Login";
import RegisterForm from "./RegisterForm";
import Recharge from "./Recharge";
import PaymentSuccess from "./PaymentSuccess";
import RechargePlans from "./RechargePlans";
import AdminLogin from "./dminLogin";
import AdminDashboard from "./AdminDashboard";
import AboutUs from "./components/Aboutus";
import PaymentCheckoutPage from "./PaymentCheckoutPage";
import PaymentSuccessRedirect from "./PaymentSuccessRedirect";
import AfterPaymentSuccess from "./AfterPaymentSuccess";
import UserDetails from "./UserDetails";
import ReportTheIssue from "./ReportTheIssue";


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/calc" element={<CalculatorPage />} />
        <Route path="/recharge" element={<Recharge />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/paymentSuccess" element={<PaymentSuccess />} />
        <Route path="/rechargePlans" element={<RechargePlans />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/adminDashboard" element={<AdminDashboard />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/paymentCheckout" element={<PaymentCheckoutPage />} />
        <Route path="/paymentsuccessredirect" element={<PaymentSuccessRedirect />} />
        <Route path="/afterPaymentSuccess" element={<AfterPaymentSuccess />} />
        <Route path="/userDetails" element={<UserDetails/>} />
        <Route path="/report" element={<ReportTheIssue/>} />
        


        {/* Add more Route components for other paths if needed */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
