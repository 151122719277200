// src/PaymentSuccess.js
import React from "react";
import "./styleFolder/PaymentSuccess.css";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();

  const handlePress = () => {
    navigate("/calc");
  };
  return (
    <div className="payment-success-container">
      <div className="payment-success-message">
        <h1>Payment Successful</h1>
        <p>
          Your payment has been processed successfully. Thank you for your
          Recharge!
        </p>
        <button onClick={handlePress} className="btn-home">
          Go to Homepage
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
