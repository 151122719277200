// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig2 = {
  apiKey: "AIzaSyCme_UWPbcSxU-PHxUY3qxZS8BbIWuwYaU",
  authDomain: "timesheet-9363d.firebaseapp.com",
  projectId: "timesheet-9363d",
  storageBucket: "timesheet-9363d.appspot.com",
  messagingSenderId: "829189960636",
  appId: "1:829189960636:web:a88cee584380c39b6bf1b9",
  measurementId: "G-FYHC7EWYXF"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
