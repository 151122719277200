// RazorpayButton.js
import React, { useRef, useEffect } from 'react';

const RazorpayButton = () => {
  const formRef = useRef(null);

  useEffect(() => {
    // Function to dynamically create and add the script
    const addScriptToForm = () => {
      if (formRef.current) {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/payment-button.js';
        script.dataset.payment_button_id = 'pl_OhEpv7N6glrOaw'; // Replace with your actual button ID
        script.async = true;

        // Append the script to the form
        formRef.current.appendChild(script);

        // Cleanup the script when the component unmounts
        return () => {
          if (formRef.current) {
            formRef.current.removeChild(script);
          }
        };
      }
    };

    addScriptToForm();
  }, []);

  return (
    <div>
      <form ref={formRef}>
        {/* Razorpay button will be rendered here */}
      
      </form>
    </div>
  );
};

export default RazorpayButton;
