// RegisterForm.js

import React, { useState } from "react";
import "./styleFolder/RegisterForm.css";

import { initializeApp } from "firebase/app";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { app } from "./CalculatorPage";
import { useNavigate } from "react-router-dom";

// const firebaseConfig = {
//   apiKey: "AIzaSyCme_UWPbcSxU-PHxUY3qxZS8BbIWuwYaU",
//   authDomain: "timesheet-9363d.firebaseapp.com",
//   projectId: "timesheet-9363d",
//   storageBucket: "timesheet-9363d.appspot.com",
//   messagingSenderId: "829189960636",
//   appId: "1:829189960636:web:a88cee584380c39b6bf1b9",
//   measurementId: "G-FYHC7EWYXF",
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Initialize Firebase

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission here (e.g., send data to backend)
   // Replace with your submission logic

    const auth = getAuth();
    createUserWithEmailAndPassword(auth, formData?.email,formData?.password)
      .then((userCredential) => {
        alert("Account created successfully!")
        // Signed up
        const user = userCredential;
        // ...
        navigate("/");
        
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        alert(errorMessage)
      });
  };
  const navigate = useNavigate();
  return (
    <form onSubmit={handleSubmit} className="register-form">
        
  <h2 style={{color:'#000'}}>Create new account</h2>

      <input
        type="text"
        placeholder="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
      />
      <input
        type="email"
        placeholder="Email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <input
        type="password"
        placeholder="Password"
        name="password"
        value={formData.password}
        onChange={handleChange}
        required
      />
      <button type="submit" className="register-button">
        Register
      </button>
      <div className="register-link">
        Already have an account? <a href="/">Login</a>
      </div>
    </form>
  );
};

export default RegisterForm;
