import React from "react";
import "./styleFolder/Loader.css"; // Import the CSS file for the spinner

const MyLoader = () => {
  return (
    <div style={loaderStyle}>
      <div className="spinner"></div>
    </div>
  );
};

const loaderStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default MyLoader;
