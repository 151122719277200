// src/Recharge.js
import React, { useState } from "react";
import "./styleFolder/Recharge.css";
import { useNavigate } from "react-router-dom";

const Recharge = () => {
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Add submit logic here
  //   alert(`Recharging with amount: $${amount}`);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (amount === "") {
      alert("please enter amount");
    } else {
      var options = {
        key: "rzp_test_m8dmpOUKZ9faIt",
        key_secret: "z2mtxkWz5trzKliAsjDGrj0V",
        amount: amount,
        currency: "INR",
        name: "MUJJU.IN",
        description: "Recharge wallet",
        handler: function (response) {
          // alert(response.razorpay_payment_id);
          console.log("razorpay respose", response);
          if (response.razorpay_payment_id) {
            navigate("/paymentSuccess");
          }
        },
        // prefill: {
        //   name: "Velmurugan",
        //   email: "mvel1620r@gmail.com",
        //   contact: "7904425033",
        // },
        notes: {
          address: "Razorpay Corporate office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  };

  return (
    <div className="recharge-container">
      <h1>Recharge Your Account</h1>
      <form onSubmit={handleSubmit} className="recharge-form">
        <div className="form-group">
          <label htmlFor="amount">Amount</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={handleAmountChange}
            placeholder="Enter amount"
            required
          />
        </div>
        <button type="submit" className="recharge-button">
          Recharge
        </button>
      </form>
    </div>
  );
};

export default Recharge;
