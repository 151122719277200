import moment from 'moment';
import React, { useState, useEffect } from 'react';

export default function UserDetails() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Replace this URL with the actual API endpoint
    const apiUrl = 'https://nodejsapifinal.vercel.app/variable-details';

    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();

        // Assuming the API response has a field named 'loginDate' or 'timestamp'
        // which is used to sort the data. Adjust field names as necessary.
        result.sort((a, b) => new Date(b.loginDate) - new Date(a.loginDate));

        setData(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div style={styles.loading}>Loading...</div>;
  if (error) return <div style={styles.error}>Error: {error}</div>;

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>User Details</h1>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.header}>Sno</th>
            <th style={styles.header}>Name</th>
            <th style={styles.header}>In Time</th>
            <th style={styles.header}>Login Date</th>
            <th style={styles.header}>Login Time</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} style={styles.row}>
              <td style={styles.cell}>{index+1}</td>
              <td style={styles.cell}>{item.name}</td>
              <td style={styles.cell}>{item.time}</td>
              <td style={styles.cell}>{moment(item?.date).format("MMMM D, YYYY")}</td>
              <td style={styles.cell}>{moment(item?.date).format("hh mm  A")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

// Styling object
const styles = {
  container: {
    backgroundColor: 'black',
    color: 'white',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  heading: {
    color: 'white',
    marginBottom: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  header: {
    backgroundColor: '#333',
    color: 'white',
    padding: '10px',
    border: '1px solid white',
  },
  row: {
    backgroundColor: '#222',
  },
  cell: {
    color: 'white',
    padding: '10px',
    border: '1px solid white',
  },
  loading: {
    color: 'white',
    textAlign: 'center',
    padding: '20px',
  },
  error: {
    color: 'red',
    textAlign: 'center',
    padding: '20px',
  },
};
