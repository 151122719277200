// src/AboutUs.js

import React from 'react';
import '../styleFolder/Aboutus.css'; // Optional: If you want to add custom styles

const AboutUs = () => {
  return (
    <div className="about-us">
      <header className="about-us-header">
        <h1>About Us</h1>
      </header>
      <section className="about-us-content">
        <h2>Welcome to Mujju!</h2>
        <p>
          At Mujju, located in Vellore, Tamil Nadu, India, we are dedicated to providing exceptional services through our website, <a href="https://mujju.in" target="_blank" rel="noopener noreferrer">Mujju.in</a>. Our goal is to enhance your experience and deliver top-notch solutions tailored to your needs.
        </p>
        <h3>Our Mission</h3>
        <p>
          Our mission is to provide innovative and user-centric solutions, ensuring the best possible experience for our users. We are committed to the security and privacy of your personal data, and we continuously work to improve our services to better meet your expectations.
        </p>
        <h3>Our Team</h3>
        <p>
          Our team comprises dedicated professionals who are passionate about technology and customer service. We strive to create a user-friendly environment and are always available to assist you with any questions or concerns.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or need assistance, feel free to reach out to us:
        </p>
        <address>
         
        MUJJU.In<br/> Bypass Road, Vellore - 632 001<br />
          Phone: +91 8508630958<br />
          Email: <a href="mailto:support@mujju.in">support@mujju.in</a>
        </address>
      </section>
    </div>
  );
};

export default AboutUs;
