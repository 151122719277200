// src/Modal.js
import React, { useState } from "react";
import "./styleFolder/DashboardModal.css"; // Ensure this file exists

const DashboardModal = ({ isOpen, onClose }) => {
  const [amount, setAmount] = useState("");
  const [rechargeResponse, setRechargeResponse] = useState('');

  const rechargeAllWallet = async () => {
    try {
      // Make sure `amount` is a number before sending
      const amountValue = Number(amount);
      if (isNaN(amountValue) || amountValue <= 0) {
        throw new Error('Invalid amount');
      }

      const res = await fetch('https://nodejsapifinal.vercel.app/recharge', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: amountValue }), // Ensure the payload matches the server's expectation
      });

      if (!res.ok) {
        // Handle the error response from the server
        const errorData = await res.json();
        throw new Error(`Network response was not ok: ${errorData.message || 'Unknown error'}`);
      }

      const result = await res.json();
      // console.log('Recharge result:', result);
      setRechargeResponse(result.message || 'Recharge successful');
      alert(rechargeResponse)
    } catch (error) {
      console.error('Error:', error);
      setRechargeResponse('Recharge failed');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate amount
    const amountValue = Number(amount);
    if (isNaN(amountValue) || amountValue <= 0) {
      setRechargeResponse('Invalid amount');
      return;
    }


    setAmount(""); // Clear the input field
    rechargeAllWallet(); // Call the async function
    onClose(); // Close the modal
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          X
        </button>
        <h2>Recharge Wallet</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="amount">Recharge Amount</label>
            <input
              type="number"
              id="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </form>
       
      </div>
    </div>
  );
};

export default DashboardModal;
