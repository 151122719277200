import React from 'react';

const MyCheckbox = ({ isChecked, handleCheckboxChange }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
      <label style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          style={{ marginRight: '8px',marginTop:'8px',height:"12.5px",width:"12.5px" }} // Adjust spacing between checkbox and text
        />
        <span>Remove duplicte entries</span>
      </label>
      <p style={{ marginLeft: '16px' }}>
        {/* Checkbox is {isChecked ? 'checked' : 'unchecked'} */}
      </p>
    </div>
  );
};

export default MyCheckbox;
