import React from "react";
import RazorpayButton from "./components/RazorpayButton";

export default function PaymentCheckoutPage() {
  return (
    <div style={containerStyle}>
      <div style={warningStyle}>
        <p style={warningTextStyle}>
          ⚠️ Important : After payment is done .Please click Done button only
          then balance will be updated !!
        </p>
      </div>
      <div style={contentStyle}>
        <h1 style={textStyle}>Payment Checkout Page</h1>
        <p style={textStyle}>
          <strong>Basic Plan:</strong> Get 30 hits for just ₹12!
        </p>
        <RazorpayButton />
      </div>
    </div>
  );
}

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "#282c34", // Optional: Add a background color for contrast
};

const contentStyle = {
  textAlign: "center",
};

const textStyle = {
  color: "white",
};

const warningStyle = {
  width: "100%", // Full width to ensure it spans the top
  padding: "10px",
  backgroundColor: "#FF0F0F", // A color that stands out for the warning
  textAlign: "center",
  position: "absolute", // Fixes the warning message at the top
  top: 0,
  left: 0,
};

const warningTextStyle = {
  color: "#333", // A dark color for the warning message text
  fontSize: "16px",
  fontWeight: "bold",
  margin: 0,
};
