import React from "react";
import { useNavigate } from "react-router-dom";

export default function AfterPaymentSuccess() {
  const navigate = useNavigate();
  const handleGoHome = () => {
    navigate("/calc");
  };
  return (
    <div style={styles.container}>
      <div style={styles.messageContainer}>
        <h1 style={styles.title}>Payment Successful!</h1>
        <p style={styles.amount}>Amount: ₹12</p>
        <p style={styles.warning}>
          Warning: Do not press the refresh or back button.
        </p>
        <button style={styles.button} onClick={handleGoHome}>
          Go to Home
        </button>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#000000",
    color: "#333",
  },
  messageContainer: {
    textAlign: "center",
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  title: {
    margin: "0 0 10px 0",
    color: "#4CAF50",
  },
  amount: {
    margin: "0 0 20px 0",
    fontSize: "18px",
    color: "#555",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#4CAF50",
    color: "white",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  warning: {
    color: "red", // or any color that fits your design
    fontSize: "14px", // adjust as needed
    marginTop: "10px",
    textAlign: "center",
  },
};
