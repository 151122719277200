import React from "react";

import "../styleFolder/terms.css";
const TermsAndConditions = () => {
  const pageStyle = {
    background: "black",
    color: "white",
    padding: "20px",
  };
  return (
    <div className="terms-and-conditions" style={pageStyle}>
    <h1>Terms and Conditions for Timesheet Calculator Tool</h1>

    <h2>Acceptance of Terms</h2>
    <p>
      By using the Timesheet Calculator Tool on mujju.in, you agree to adhere to these terms and conditions. If you do not agree with any part of these terms, refrain from using the tool.
    </p>

    <h2>Accuracy of Information</h2>
    <p>
      The Timesheet Calculator Tool provides a service to assist users in calculating time-related data. Users are responsible for ensuring the accuracy of the information entered into the tool.
    </p>

    <h2>Privacy and Data Handling</h2>
    <p>
      At mujju.in, we respect user privacy and handle data in accordance with our Privacy Policy. Information entered into the timesheet calculator will only be used for the intended purpose of calculating timesheet data.
    </p>

    <h2>User Conduct</h2>
    <p>
      Users are expected to use the Timesheet Calculator Tool responsibly and lawfully. Any misuse, including but not limited to unauthorized access or interference with the tool, is prohibited.
    </p>

    <h2>Intellectual Property</h2>
    <p>
      The Timesheet Calculator Tool and its content on mujju.in are protected by intellectual property rights. Users may not reproduce, modify, distribute, or republish any part of the tool without explicit permission.
    </p>

    <h2>Payment Terms</h2>
    <ul>
      <li><strong>Charges:</strong> Access to certain features of the Timesheet Calculator Tool may require payment. The details of the applicable charges will be provided at the time of service request or subscription.</li>
      <li><strong>Non-Refundable Payments:</strong> All payments made for the Timesheet Calculator Tool are non-refundable. Once a payment is made, it cannot be refunded under any circumstances.</li>
      <li><strong>Service Recharge:</strong> Payments are considered a recharge for the service and do not constitute a purchase of any physical goods or long-term entitlement to the service beyond the specified access period.</li>
    </ul>

    <h2>Refund and Cancellation Policy</h2>
    <ul>
      <li><strong>No Refunds on Cancellation:</strong> All payments are final and non-refundable. In the event of a cancellation or any request for a refund, no refunds will be issued under any circumstances.</li>
      <li><strong>Service Termination:</strong> If you choose to cancel your service or subscription, you will not be entitled to any refund or prorated credit for unused service time.</li>
    </ul>

    <h2>Limitation of Liability</h2>
    <p>
      We do not guarantee the accuracy, reliability, or completeness of the Timesheet Calculator Tool on mujju.in. We are not liable for any damages or losses resulting from the use or inability to use the tool, including but not limited to financial losses due to incorrect calculations or issues related to payments.
    </p>

    <h2>Modifications to Terms</h2>
    <p>
      We reserve the right to update or modify these terms and conditions at any time for the Timesheet Calculator Tool on mujju.in. Users will be notified of any significant changes, and continued use of the tool implies acceptance of the revised terms.
    </p>

    <h2>Governing Law</h2>
    <p>
      These terms and conditions for the Timesheet Calculator Tool on mujju.in are governed by and construed in accordance with the laws of India. Any disputes arising shall be subject to the exclusive jurisdiction of the courts in India.
    </p>
  </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    maxWidth: "800px",
    margin: "auto",
    backgroundColor: "black",
    color: "white",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
  heading: {
    color: "#FFD700", // Optional: Change heading color for better visibility
  },
  subheading: {
    color: "#FFD700", // Optional: Change subheading color for better visibility
  },
  text: {
    color: "white",
  },
};

export default TermsAndConditions;
