import React from 'react';

import '../styleFolder/terms.css'
const ContactUs = () => {
  return (
    <div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdRG_OH7F27OsL9oW5uYAIz0fwNA9AG7SfhDW18PaaUKFpFow/viewform?embedded=true" width="1250" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  );
};


export default ContactUs;
